import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import {from, Observable, of} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {mergeMap} from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authService.isAuthenticated()
            .pipe(mergeMap(authenticated => {
                    if (authenticated) {
                        return from(this.authService.credentials());
                    }
                    return of({});
                }),
                mergeMap((credentials: any) => {
                    if (credentials.mobile && credentials.password) {
                        return next.handle(req.clone({
                            body: {...req.body, mobile: credentials.mobile, password: credentials.password}
                        }));
                    }
                    return next.handle(req);
                })
            );
    }

}
