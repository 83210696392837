export enum Config {
    baseUrl = 'https://kargarin.ir/webservice',
    baseUrlImage = 'https://kargarin.ir/images/advertising/',
    baseUrlSliders = 'https://kargarin.ir/images/sliders/',
    versionCode = '0.0.1'
}

export enum Api {
    Main = '/main',
    Login = '/users/register',
    Verify = '/users/verification',
    Suggestion = '/message_box/suggested_work',
    Advertise = '/advertising/get_advertising',
    DetailLabor = '/labor/labor_request_details',
    DetailSkill = '/skilled_workers/expert_request_details',
    PersonalInformation = '/users/edit_profile',
    LaborRegister = '/labor/complete_second_step_profile',
    SkilledRegister = '/skilled_workers/complete_second_step_profile',
    EngineerRegister = '/engineer/complete_second_step_profile',
    LaborRqs = '/employers/labor_request',
    SkilledRqs = '/employers/expert_request',
    InfoLabor = '/info/labor_register_info',
    InfoSkill = '/skilled_workers/get_skills',
    SkillTool = '//skilled_workers/get_info',
    InfoEngineer = '/engineer/needed_info',
    LaborProfileData = '/labor/second_step_profile',
    SkillProfileData = '/skilled_workers/second_step_profile',
    AcceptRqs = '/skilled_workers/accept_request',
    AcceptLaborRqs = '/labor/accept_request',
    RqsMe = '/message_box/my_projects',
    LaborAccept = '/employers/labor_request_details',
    SkillAccept = '/employers/expert_request_details',
    EmpLaborProfile = '/employers/labor_profile',
    EmpSkillProfile = '/employers/skillworker_profile',
    EmpEngineerProfile = '/employers/engineer_profile',
    AdvRegistre = '/advertising/register',
    Profile = '/users/profile',
    MyAdv = '/advertising/my_ads',
    Cities = '/info/cities',
    District = '/info/districts',
    AdvDetail = '/advertising/details',
    CatFilter = '/info/cat_childs',
    CancelRqsLabor = '/employers/cancel_request_labor',
    CancelRqsSkill = '/employers/cancel_request_expert',
    AcceptSkillWorker = '/employers/accept_skillworker',
    AcceptLabor= '/employers/accept_labor',
    Transaction= '/main/transactions',
    Resend= '/users/resend_activation_code',
    CancelAcceptSkill= '/skilled_workers/cancel_request',
    CancelAcceptLabor= '/labor/cancel_request',
    AcceptedProjectSkill= '/skilled_workers/user_accepted_projects',
    EngineerProfile= '/engineer/profile',
    Credit= '/skilled_workers/user_accepted_projects',
    AfterCall= '/employers/register_aftercall',
    Decline= 'employers/decline'





}
