import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent, LogOutDialogComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import {Device} from '@ionic-native/device/ngx';
import {IonicStorageModule} from '@ionic/storage';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {AuthInterceptor} from './http/auth.interceptor';
import {FormDataInterceptor} from './http/formData.interceptor';
import {ErrorInterceptor} from './http/error.interceptor';
import { MatDialogModule } from '@angular/material/dialog';
import { File } from '@ionic-native/file/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';



@NgModule({
    declarations: [AppComponent,LogOutDialogComponent],
    entryComponents: [LogOutDialogComponent],
    imports: [
        BrowserModule,
        MatIconModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        IonicStorageModule.forRoot(),
        BrowserAnimationsModule,
        MatSnackBarModule,
        MatDialogModule,
       

    ],
    providers: [
        StatusBar,
        Device,
        Storage,
        SplashScreen,
        File,
        SocialSharing,
        LogOutDialogComponent,
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {
                duration: 2500,
                direction: 'rtl'
            }
        },
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: FormDataInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
