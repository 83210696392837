import { Component, ViewChild } from '@angular/core';

import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MatIconRegistry, MatDialogRef, MatDialog } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import {UserService} from "./services/user.service";
import { timer } from 'rxjs';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  splash = true;
  isCordova = false;

  public appPages = [
    {
      title: 'معرفی به دوستان',
      url: '/chat',
      icon: 'users',

    },
    {
      title: 'بازدید وبلاگ',
      url: '/home',
      icon: 'internet'
    },
    {
      title: 'قوانین و مقررات',
      url: '/setting',
      icon: 'documents'
    },
    {
      title: 'تماس با ما و پشتیبانی',
      url: '/about-us',
      icon: 'call-me'
    },
    {
      title: 'آموزش',
      url: '/about-us',
      icon: 'education'
    },
    {
      title: 'درباره ما',
      url: '/about-us',
      icon: 'about-us'
    },
  
  ];

  @ViewChild('appmenu', { static: true }) menu;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private userService: UserService,
    public dialog: MatDialog

  ) {
    this.initializeApp();
    this.matIconRegistry.addSvgIcon(
      'keyboard',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/keyboard.svg'),

    );
    this.matIconRegistry.addSvgIcon(
      'users',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/users.svg'),

    );
    this.matIconRegistry.addSvgIcon(
      'logout',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/logout.svg'),

    );
    this.matIconRegistry.addSvgIcon(
      'internet',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/internet.svg'),

    );
    this.matIconRegistry.addSvgIcon(
      'documents',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/documents.svg'),

    );
    this.matIconRegistry.addSvgIcon(
      'call-me',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/call-me.svg'),

    );
    this.matIconRegistry.addSvgIcon(
      'education',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/education.svg'),

    );
    this.matIconRegistry.addSvgIcon(
      'about-us',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/about-us.svg'),

    );
    this.matIconRegistry.addSvgIcon(
      'buy',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/buy.svg'),

    );
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(LogOutDialogComponent, {
      maxWidth: '80vw',
      width: '80vw',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.menu.close();
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.isCordova = true;
      }
      timer(3000).subscribe(() => this.splash = false);
      this.statusBar.overlaysWebView(true);
      this.splashScreen.hide();
    });
    this.userService.getUserInfo();

  }
}
@Component({
  selector: 'app-logout-dialog',
  templateUrl: 'logout-dialog.component.html',
})
export class LogOutDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<LogOutDialogComponent>, private authService: AuthService, private navctl: NavController) {
  }

  close(): void {
    this.dialogRef.close();
  }

  logout(): void {
    this.authService.logout().subscribe(() => {
      this.close();

      this.navctl.navigateRoot('/login');
    });
  }

}