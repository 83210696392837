import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuard} from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'verify-login', loadChildren: './verify-login/verify-login.module#VerifyLoginPageModule' },
  { path: 'profile', loadChildren: './profile/profile.module#ProfilePageModule' },
  { path: 'advertise', loadChildren: './advertise/advertise.module#AdvertisePageModule' },
  { path: 'personal-information', loadChildren: './personal-information/personal-information.module#PersonalInformationPageModule' },
  { path: 'work-box', redirectTo: '/tabs/work-box' },
  { path: 'worker-request', loadChildren: './worker-request/worker-request.module#WorkerRequestPageModule' },
  { path: 'specialist-request', loadChildren: './specialist-request/specialist-request.module#SpecialistRequestPageModule' },
  { path: 'worker-profile', loadChildren: './worker-profile/worker-profile.module#WorkerProfilePageModule' },
  { path: 'master-profile', loadChildren: './master-profile/master-profile.module#MasterProfilePageModule' },
  { path: 'engineer-profile', loadChildren: './engineer-profile/engineer-profile.module#EngineerProfilePageModule' },
  { path: 'skilled-workers-detail', loadChildren: './skilled-workers-detail/skilled-workers-detail.module#SkilledWorkersDetailPageModule' },
  { path: 'my-request', loadChildren: './my-request/my-request.module#MyRequestPageModule' },
  { path: 'detail-project', loadChildren: './detail-project/detail-project.module#DetailProjectPageModule' },
  { path: 'reserv', loadChildren: './reserv/reserv.module#ReservPageModule' },
  { path: 'adv-store', loadChildren: './adv-store/adv-store.module#AdvStorePageModule' },
  { path: 'my-adv', loadChildren: './my-adv/my-adv.module#MyAdvPageModule' },
  { path: 'wallet', loadChildren: './wallet/wallet.module#WalletPageModule' },
  { path: 'adv-detail', loadChildren: './adv-detail/adv-detail.module#AdvDetailPageModule' },
  { path: 'credit', loadChildren: './credit/credit.module#CreditPageModule' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
