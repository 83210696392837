import { Injectable, NgZone } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AlertController, NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private alertController: AlertController,
        private navCtl: NavController,
        private route: ActivatedRoute,
    


    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    if (event.body.status && event.body.show_message_dialog) {
                        this.alertController.create({
                            header: event.body.message_dialog.title,
                            message: event.body.message_dialog.body,
                            buttons: [...event.body.message_dialog.actions, 'باشه'],
                            mode: 'ios'
                        }).then(alert => alert.present());
                    }
                    if (event.body.status && event.body.status === 'error' && event.body.credit) {
                        this.alertController.create({
                            header: 'خطا',
                            message: event.body.message,
                            buttons: [{
                                text: 'شارژ‌ حساب',
                                handler: async () => {
                                    const params = location.pathname.split("/");
                                    if (params[1] === 'skilled-workers-detail') {
                                        console.log('test');
                                        this.navCtl.navigateForward(['/credit', params[2], params[3]]);
                                    }
                                    console.log(params);
                                    if (params[1] === 'detail-project') {
                                        console.log('test');
                                        this.navCtl.navigateForward(['/credit', params[4], params[3]]);
                                    }
                                    if (params[1] === 'reserv') {
                                        console.log('test');
                                        this.navCtl.navigateForward(['/credit', params[4], params[3]]);
                                    }
                               
                                }
                            }],
                            mode: 'ios'
                        }).then(alert => alert.present());
                    } else if (event.body.status && event.body.status === 'error' && event.body.message) {
                        this.alertController.create({
                            header: 'خطا',
                            message: event.body.message,
                            buttons: ['باشه'],
                            mode: 'ios'
                        }).then(alert => alert.present());
                    }
                }
            })
        );
    }

}
