import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api, Config } from '../config';
import { Storage } from '@ionic/storage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { baseApiUrl } from 'mapbox-gl';
import { promise } from 'protractor';

export enum WorkType {
    Labor = 'labor',
    Skilled = 'skilledworker',
    Engineer = 'engineer',
}
@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private http: HttpClient,
        private storage: Storage
    ) {
    }

    getUserInfo(): void {
        this.http.post(Config.baseUrl + Api.Main, {})
            .subscribe((data: any) => {
                console.log(data);
                this.storage.set('user-info', data.user_info);

            });
    }
    async isLabor(): Promise<boolean> {

        const user_info = await this.storage.get('user-info');
        console.log(user_info);
        return user_info.labor;
    }
    async isSkilled(): Promise<boolean> {
        const user_info = await this.storage.get('user-info');
        return user_info.skilledworker;
    }
    async isEngineer(): Promise<boolean> {
        const user_info = await this.storage.get('user-info');
        return user_info.engineer;
    }
    async hasGeneral(): Promise<boolean> {
        const user_info = await this.storage.get('user-info');
        return user_info.general_info;
    }
    async userInfo(): Promise<any>{
        const user_info = await this.storage.get('user-info');
        return user_info;
    }
    EditPrifile(data): Observable<boolean> {
        return this.http.post(Config.baseUrl + Api.PersonalInformation, data)
            .pipe(
                map((result: any) => {
                    return result.status === 'ok';
                })
            );
    }
    EditPrifileLabor(data): Observable<boolean> {
        return this.http.post(Config.baseUrl + Api.LaborRegister, data)
            .pipe(
                map((result: any) => {
                    return result.status === 'ok';
                })
            );
    }
    laborProfileData(): Observable<any> {
        return this.http.post(Config.baseUrl + Api.LaborProfileData, {});

    } 
    engineerProfileData(): Observable<any> {
        return this.http.post(Config.baseUrl + Api.InfoEngineer, {});

    } 
    engineerProfile(): Observable<any> {
        return this.http.post(Config.baseUrl + Api.EngineerProfile, {});

    }
    skillProfileData(): Observable<any> {
        return this.http.post(Config.baseUrl + Api.SkillProfileData, {});

    }
    profile(): Observable<any> {
        return this.http.post(Config.baseUrl + Api.Profile, {});

    }
    EditPrifileSkilled(data): Observable<boolean> {
        return this.http.post(Config.baseUrl + Api.SkilledRegister, data)
            .pipe(
                map((result: any) => {
                    return result.status === 'ok';
                })
            );
    }

    EditPrifileEngineer(data): Observable<boolean> {
        return this.http.post(Config.baseUrl + Api.EngineerRegister, data)
            .pipe(
                map((result: any) => {
                    return result.status === 'ok';
                })
            );
    }
    laborRqs(data): Observable<boolean> {
        return this.http.post(Config.baseUrl + Api.LaborRqs, data)
            .pipe(
                map((result: any) => {
                    return result.status === 'ok';
                })
            );
    }
    skillRqs(data): Observable<boolean> {
        return this.http.post(Config.baseUrl + Api.SkilledRqs, data)
            .pipe(
                map((result: any) => {
                    return result.status === 'ok';
                })
            );
    }
  
    Skills(): Observable<any> {
        return this.http.post(Config.baseUrl + Api.InfoLabor, {})
            .pipe(
                map((result: any) => {
                    return result.skills;

                })
            )

    }
    tools(): Observable<any> {
        return this.http.post(Config.baseUrl + Api.InfoLabor, {})
            .pipe(
                map((result: any) => {
                    return result.tools;

                })
            )

    }
    skillWorker(): Observable<any> {
        return this.http.post(Config.baseUrl + Api.InfoSkill, { category: 8 })
            .pipe(
                map((result: any) => {
                    console.log(result)
                    return result.skills;

                })
            )

    }
    cities(province: number): Observable<any> {
        return this.http.post(Config.baseUrl + Api.Cities, { province })
            .pipe(
                map((result: any) => {
                    return result.cities;

                })
            )

    }
    district(city: number): Observable<any> {
        return this.http.post(Config.baseUrl + Api.District, { city })
            .pipe(
                map((result: any) => {
                    return result.districts;

                })
            )

    }
    city(): Observable<any> {
        return this.http.post(Config.baseUrl + Api.Main, {})
            .pipe(
                map((result: any) => {
                    return result.cities;

                })
            )

    }
    provinces(): Observable<any> {
        return this.http.post(Config.baseUrl + Api.Main, {})
            .pipe(
                map((result: any) => {
                    return result.provinces;

                })
            )

    }
    getMain(): Observable<any> {
        return this.http.post(Config.baseUrl + Api.Main , {});
    }
    sendCity(city: number): Observable<any>{
        return this.http.post(Config.baseUrl + Api.Main, {city});
    }
    getCat(): Observable<any> {
        return this.http.post(Config.baseUrl + Api.CatFilter, {})

            .pipe(
                map((result: any) => {
                    return result;

                })
            )
    }
    getSubCat(parent: number): Observable<any> {
        return this.http.post(Config.baseUrl + Api.CatFilter, { parent })

            .pipe(
                map((result: any) => {
                    return result;

                })
            )
    }

    skillTools(): Observable<any> {
        return this.http.post(Config.baseUrl + Api.SkillTool, {})
            .pipe(
                map((result: any) => {
                    return result.tools;

                })
            )

    }
    skillEngineer(): Observable<any> {
        return this.http.post(Config.baseUrl + Api.InfoEngineer, {})
            .pipe(
                map((result: any) => {
                    return result.categories;

                })
            )

    }
    educationEngineer(): Observable<any> {
        return this.http.post(Config.baseUrl + Api.InfoEngineer, {})
            .pipe(
                map((result: any) => {
                    return result.education;

                })
            )

    }
    licenseEngineer(): Observable<any> {
        return this.http.post(Config.baseUrl + Api.InfoEngineer, {})
            .pipe(
                map((result: any) => {
                    return result.license;

                })
            )

    }
    acceptRqs(data): Observable<any> {
        return this.http.post(Config.baseUrl + Api.AcceptRqs, data)
            .pipe(
                map((result: any) => {
                    return result.status === 'ok';
                })
            );

    }
    acceptLaborRqs(request: number): Observable<any> {
        return this.http.post(Config.baseUrl + Api.AcceptLaborRqs, { request })
            .pipe(
                map((result: any) => {
                    return result.status === 'ok';
                })
            );

    }
    getRqsMe(): Observable<any> {
        return this.http.post(Config.baseUrl + Api.RqsMe, {});
    }
    getLaborAccept(request: number): Observable<any> {
        console.log();
        return this.http.post(Config.baseUrl + Api.LaborAccept, { request });
    }

    getSkillAccept(request: number): Observable<any> {
        return this.http.post(Config.baseUrl + Api.SkillAccept, { request });
    }
    skillAccept(skillworker: number, request: number): Observable<any> {
        return this.http.post(Config.baseUrl + Api.AcceptSkillWorker, {
            skillworker,
            request,
        });
    }
    engineerAccept(engineer_id: number, request: number): Observable<any> {
        return this.http.post(Config.baseUrl + Api.AcceptSkillWorker, {
            engineer_id,
            request,
        });
    }
    laborAccept(labor: number, request: number): Observable<any> {
        return this.http.post(Config.baseUrl + Api.AcceptLabor, {
            labor,
            request,
        });
    }

    getEmpLeborProfile(labor: number, request_id: number): Observable<any> {
        return this.http.post(Config.baseUrl + Api.EmpLaborProfile, {
            labor,
            request_id,
        });
    }
    getEmpSkillProfile(skillworker: number, request_id: number): Observable<any> {
        console.log();
        return this.http.post(Config.baseUrl + Api.EmpSkillProfile, {
            skillworker,
            request_id
        });

    }
    getEmpEngineerProfile(engineer_id: number, request_id: number): Observable<any> {
        return this.http.post(Config.baseUrl + Api.EngineerRegister, {
            engineer_id,
            request_id,
        });
    }
    advRegister(data): Observable<boolean> {
        return this.http.post(Config.baseUrl + Api.AdvRegistre, data)
            .pipe(
                map((result: any) => {
                    return result.status === 'ok';
                })
            );
    }
    getMyAdv(): Observable<any> {
        return this.http.post(Config.baseUrl + Api.MyAdv, {});
    }
    cancelRqsLabor(request: number): Observable<any> {
        return this.http.post(Config.baseUrl + Api.CancelRqsLabor, { request })
            .pipe(
                map((result: any) => {
                    return result.status === 'ok';
                })
            );
    }
    cancelRqsSkill(request: number): Observable<any> {
        return this.http.post(Config.baseUrl + Api.CancelRqsSkill, { request })
            .pipe(
                map((result: any) => {
                    return result.status === 'ok';
                })
            );
    }
    transaction(): Observable<any>{
        return this.http.post(Config.baseUrl + Api.Transaction, {});

    } 
    credit(project_type: string,request_id: number): Observable<any>{
        return this.http.post(Config.baseUrl + Api.Credit, {
            project_type,
            request_id,
        });

    }
    cancelAcceptskill(request: number): Observable<boolean>{
        return this.http.post(Config.baseUrl + Api.CancelAcceptSkill, {request})
        .pipe(
            map((result: any) => {
                return result.status === 'ok';
            })
        );
    } 
    cancelAcceptlabor(request: number): Observable<boolean>{
        return this.http.post(Config.baseUrl + Api.CancelAcceptLabor, {request})
        .pipe(
            map((result: any) => {
                return result.status === 'ok';
            })
        );
    }
    acceptProjectSkill(request_id: number,project_type: string ): Observable<boolean>{
        return this.http.post(Config.baseUrl + Api.CancelAcceptLabor, {
            request_id,
            project_type,
        })
        .pipe(
            map((result: any) => {
                return result.status === 'ok';
            })
        );
    }
    afterCall(): Observable<any> {
        console.log();
        return this.http.post(Config.baseUrl + Api.AcceptLaborRqs,{})
            .pipe(
                map((result: any) => {
                    return result.status === 'ok';
                })
            );

    } 
      decline(request_id: number,force_id: number, project_type: string): Observable<any> {
        return this.http.post(Config.baseUrl + Api.Decline, 
           {
            force_id,
            request_id,
            project_type,
           })
            .pipe(
                map((result: any) => {
                    return result.status === 'ok';
                })
            );

    }
    

}

