import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class FormDataInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req.headers.append('Content-Type', 'application/x-www-form-urlencoded');
        const formData = new FormData();
        Object.keys(req.body).forEach(param => {
            formData.append(param, req.body[param]);
        });
        return next.handle(req.clone({body: formData}));
    }

}
