import { Injectable } from '@angular/core';
import {Observable, from, AsyncSubject} from 'rxjs';
import { Config, Api } from '../config';
import { map, tap } from 'rxjs/operators';
import { Device } from '@ionic-native/device/ngx';
import { HttpClient } from '@angular/common/http';
import { NavController } from '@ionic/angular';
import {Storage} from '@ionic/storage';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  async credentials() {
    const mobile = await this.storage.get('mobile');
    const password = await this.storage.get('code');

    return {mobile, password};
  }
  isAuthenticated(): Observable<boolean> {
    return from(this.storage.get('mobile')).pipe(
      map(val => {
        return val != null;
      })
    );
  }
  login(mobile: string): Observable<boolean> {
    return this.http.post(Config.baseUrl + Api.Login, {
      mobile,
      deviceToken: 'test',
      deviceOS: 'android',
      version_code: '1.1.0',
      platform: 'web',
    }).pipe(
      map((response: any) => {
        return response.status === 'ok';
      })
    );
  }
  verify(mobile: string, code: string): Observable<boolean> {
    return this.http.post(Config.baseUrl + Api.Verify, {
      activation_code: code,
      mobile,
      deviceToken: 'test',
      deviceOS: 'android',
      version_code: '1.1.0',
      platform: 'web',
    }).pipe(
      map((response: any) => {
        return response.status === 'ok';
      }),
      tap(status => {
        if (status) {
          this.storage.set('mobile', mobile);
          this.storage.set('code', code);
          this.router.navigateRoot('/');
        }
      })
    );
  }
  resend(mobile: number): Observable<boolean>{
    return this.http.post(Config.baseUrl + Api.Resend, {
      mobile,
    }).pipe(
      map((response: any) => {
        return response.status === 'ok';
      })
    );

  }
  logout(): Observable<void> {
    return from(this.storage.clear());
  }

  constructor(
    private http: HttpClient,
    private device: Device,
    private storage: Storage,
    public router: NavController,
  ) { }
}
